import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1001.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M0 5400 l0 -5400 5005 0 5005 0 0 5400 0 5400 -5005 0 -5005 0 0
-5400z m3143 3183 c30 -93 130 -397 222 -678 298 -910 321 -979 331 -982 5 -1
20 32 33 75 13 42 55 169 92 282 37 113 133 408 214 655 81 248 174 532 208
633 l60 182 313 -2 314 -3 0 -1265 1 -1265 -242 -3 -242 -2 6 452 c5 394 20
868 42 1298 5 79 3 121 -3 115 -7 -8 -327 -953 -602 -1780 l-27 -80 -168 0
-168 0 -62 185 c-33 102 -148 444 -255 760 -106 316 -217 645 -245 731 -29 85
-57 164 -63 175 -15 26 -15 7 3 -360 25 -527 35 -852 35 -1178 l0 -318 -240 0
-240 0 0 1270 0 1270 314 0 314 0 55 -167z m2502 -1975 c150 -38 225 -168 225
-389 0 -42 -3 -90 -6 -108 l-6 -31 -302 -2 -301 -3 -3 -39 c-8 -89 42 -224
102 -281 81 -76 271 -81 392 -10 l41 24 25 -54 c28 -59 28 -60 -32 -97 -115
-70 -346 -82 -458 -23 -153 79 -223 233 -226 495 -3 296 116 490 324 526 68
12 157 9 225 -8z m1555 -177 c395 -84 576 -296 597 -701 8 -163 -31 -297 -124
-421 -38 -51 -56 -69 -136 -136 -15 -12 -49 -34 -77 -48 -28 -14 -48 -29 -46
-34 3 -5 32 -18 63 -30 74 -27 180 -107 234 -174 179 -225 210 -615 72 -896
-43 -87 -129 -192 -201 -245 -117 -85 -277 -145 -442 -166 -48 -6 -300 -10
-587 -10 l-503 0 0 1438 c0 791 3 1442 8 1446 4 5 239 6 522 3 484 -4 521 -5
620 -26z m-4580 -1595 c0 -44 12 -66 25 -46 11 17 61 11 80 -10 12 -14 20 -41
23 -78 3 -48 0 -62 -18 -85 -21 -26 -27 -28 -79 -24 l-56 4 -3 142 c-2 121 0
141 13 141 11 0 15 -11 15 -44z m1060 -98 l0 -143 -60 -3 c-53 -2 -61 0 -75
22 -9 13 -19 40 -22 59 -12 79 27 133 90 123 l37 -6 0 45 c0 33 4 45 15 45 13
0 15 -22 15 -142z m220 0 l0 -143 -59 -3 c-54 -2 -61 0 -80 24 -28 36 -28 107
0 153 19 32 22 33 65 27 l44 -6 0 45 c0 33 4 45 15 45 13 0 15 -22 15 -142z
m1315 123 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m-1015
-36 c0 -15 6 -25 14 -25 24 0 30 -21 7 -27 -19 -5 -21 -13 -21 -78 0 -65 2
-74 20 -80 28 -9 25 -25 -5 -25 -33 0 -45 31 -45 116 0 50 -3 64 -15 64 -8 0
-15 7 -15 15 0 8 7 15 15 15 8 0 15 8 15 18 0 10 3 22 7 25 13 14 23 6 23 -18z
m-3005 -35 c3 -6 15 -5 31 2 19 9 33 9 59 -1 25 -9 40 -9 54 -2 31 17 58 13
81 -11 17 -18 20 -35 20 -105 0 -67 -3 -83 -15 -83 -12 0 -15 15 -15 78 0 42
-4 82 -8 88 -14 20 -41 23 -61 6 -17 -14 -21 -31 -23 -91 -2 -49 -7 -76 -15
-79 -10 -3 -13 17 -13 75 0 43 -4 84 -9 91 -5 8 -22 12 -42 10 l-34 -3 -3 -87
c-2 -69 -6 -88 -17 -88 -12 0 -15 20 -15 105 0 95 7 123 25 95z m425 -10 c17
-17 20 -33 20 -105 0 -77 -8 -102 -25 -75 -4 7 -12 7 -25 0 -31 -17 -68 -12
-84 12 -33 47 -6 89 62 98 28 4 38 10 40 27 6 40 -59 53 -81 16 -11 -17 -27
-17 -27 1 0 21 40 46 72 46 16 0 37 -9 48 -20z m106 9 c4 -8 9 -8 17 0 13 13
47 15 47 2 0 -6 -12 -16 -27 -23 -26 -12 -28 -18 -33 -92 -3 -51 -10 -81 -17
-84 -10 -3 -13 22 -13 102 0 97 7 125 26 95z m127 -60 c10 -35 23 -68 27 -73
5 -5 19 25 31 68 14 50 27 76 37 76 8 0 12 -6 9 -13 -3 -8 -20 -61 -37 -118
-42 -137 -54 -159 -85 -159 -14 0 -25 5 -25 10 0 6 7 10 15 10 8 0 22 12 32
26 16 25 15 30 -15 123 -35 106 -38 125 -20 119 7 -3 21 -33 31 -69z m257 61
c17 -9 40 -58 40 -86 0 -9 -19 -14 -62 -16 -56 -3 -63 -5 -66 -25 -2 -15 5
-27 23 -39 23 -15 32 -16 58 -6 37 14 50 6 23 -14 -31 -22 -83 -18 -111 11
-20 19 -25 34 -25 74 0 83 60 133 120 101z m108 -2 c9 -9 15 -9 24 0 17 17 58
15 80 -5 15 -13 18 -32 18 -105 0 -67 -3 -89 -12 -86 -9 3 -15 34 -18 89 -5
83 -5 84 -33 87 -47 6 -57 -12 -57 -100 0 -59 -3 -79 -12 -76 -15 5 -24 208
-10 208 4 0 13 -5 20 -12z m292 2 c17 -9 40 -58 40 -86 0 -9 -19 -14 -62 -16
-56 -3 -63 -5 -66 -25 -2 -15 5 -27 23 -39 23 -15 32 -16 58 -6 37 14 50 6 23
-14 -31 -22 -83 -18 -111 11 -20 19 -25 34 -25 74 0 83 60 133 120 101z m421
-20 c10 -14 21 -37 25 -52 l6 -28 -65 0 c-36 0 -68 -4 -72 -9 -10 -16 18 -61
41 -67 12 -3 31 -1 42 5 34 18 47 13 27 -9 -23 -26 -85 -27 -108 -2 -42 46
-42 123 -1 168 31 33 79 30 105 -6z m104 20 c4 -7 12 -7 25 0 27 14 51 12 72
-7 15 -13 18 -32 18 -105 0 -52 -4 -88 -10 -88 -6 0 -10 28 -10 64 0 89 -12
116 -50 116 -39 0 -52 -31 -48 -114 2 -39 -1 -61 -9 -63 -8 -3 -12 18 -15 64
-5 117 5 169 27 133z m290 -10 c14 -15 20 -41 23 -103 l4 -83 -58 -1 c-75 -2
-94 8 -94 52 0 38 28 65 70 65 37 0 53 14 45 39 -9 29 -56 32 -86 5 -25 -22
-31 -3 -7 23 27 29 78 31 103 3z m110 10 c4 -6 13 -5 25 2 15 10 22 10 32 0 9
-9 5 -14 -20 -23 -36 -12 -45 -40 -41 -121 2 -38 -1 -53 -10 -53 -9 0 -12 24
-13 90 0 50 0 96 1 103 1 14 17 16 26 2z m645 -10 c17 -17 20 -33 20 -102 l0
-83 -59 -3 c-52 -2 -62 1 -75 20 -33 47 -12 85 54 96 39 7 45 11 45 32 0 21
-6 26 -31 28 -19 2 -36 -3 -44 -12 -16 -20 -30 -20 -30 -2 0 21 40 46 72 46
16 0 37 -9 48 -20z m325 4 c17 -12 21 -29 23 -102 l4 -88 -59 -1 c-67 -2 -93
14 -93 57 0 35 21 52 73 59 43 6 55 23 38 50 -12 19 -62 17 -73 -3 -11 -20
-28 -21 -28 -2 0 21 39 46 70 46 14 0 34 -7 45 -16z m197 -1 c17 -15 25 -43
12 -43 -3 0 -17 9 -30 19 -30 24 -64 17 -64 -13 0 -16 13 -27 53 -46 57 -27
67 -48 43 -85 -13 -19 -25 -25 -54 -25 -41 0 -82 23 -82 46 0 20 16 18 40 -6
24 -24 53 -26 71 -4 18 22 2 43 -50 63 -55 20 -68 49 -40 85 24 31 73 35 101
9z m197 -9 c12 -15 21 -40 21 -55 0 -28 -2 -29 -50 -30 -73 -2 -74 -2 -78 -21
-4 -21 32 -58 56 -58 10 0 28 7 40 16 18 12 22 12 22 2 0 -33 -58 -50 -105
-32 -24 9 -45 58 -45 103 0 86 92 135 139 75z m161 10 c0 -8 -8 -14 -18 -14
-33 0 -42 -21 -42 -103 0 -58 -3 -78 -12 -75 -13 4 -25 167 -15 192 7 17 87
17 87 0z m69 -59 c12 -42 25 -73 30 -71 5 3 16 31 25 63 18 66 32 91 42 80 12
-12 -56 -209 -69 -204 -10 3 -77 177 -77 200 0 4 6 7 13 7 9 0 23 -30 36 -75z
m186 53 c9 -43 0 -181 -13 -186 -9 -3 -12 24 -12 102 0 63 4 106 10 106 5 0
12 -10 15 -22z m182 0 c23 -22 31 -48 13 -48 -5 0 -14 9 -20 20 -7 13 -21 20
-40 20 -51 0 -76 -64 -48 -124 16 -36 45 -42 79 -17 32 23 39 26 39 11 0 -22
-41 -50 -73 -50 -42 0 -73 28 -81 75 -19 99 67 173 131 113z m198 -1 c8 -13
18 -37 21 -55 l7 -32 -62 0 c-67 0 -82 -12 -61 -49 15 -29 55 -37 85 -17 21
14 25 14 25 2 0 -28 -62 -46 -105 -30 -48 19 -60 125 -19 173 33 40 85 43 109
8z m187 6 c19 -17 24 -43 9 -43 -5 0 -14 7 -21 15 -17 20 -69 19 -77 -1 -8
-20 8 -35 60 -54 37 -14 42 -20 45 -50 4 -41 -16 -60 -67 -60 -36 0 -71 23
-71 47 0 19 13 16 33 -7 20 -24 50 -26 71 -4 19 19 5 54 -22 54 -28 0 -82 38
-82 58 0 51 82 82 122 45z"/>
<path d="M5455 6481 c-113 -20 -189 -115 -198 -246 l-2 -30 235 0 235 0 -3 60
c-4 100 -38 167 -101 199 -34 17 -115 25 -166 17z"/>
<path d="M6408 6144 c-5 -4 -8 -211 -8 -461 0 -355 3 -453 13 -454 6 -1 152
-1 323 0 342 2 380 6 485 62 130 69 201 179 221 337 20 157 -21 300 -110 389
-55 53 -103 80 -197 105 -60 17 -115 21 -395 25 -179 3 -328 2 -332 -3z"/>
<path d="M6400 4399 l0 -520 373 3 c352 3 375 4 429 25 200 75 305 230 316
464 10 234 -72 412 -225 489 -110 55 -157 60 -540 60 l-353 0 0 -521z"/>
<path d="M2632 4758 c-7 -7 -12 -36 -12 -64 0 -43 4 -56 22 -68 48 -34 78 -7
78 68 0 58 -12 76 -50 76 -14 0 -31 -5 -38 -12z"/>
<path d="M3560 4751 c-39 -73 23 -177 74 -125 19 19 22 114 4 132 -19 19 -66
15 -78 -7z"/>
<path d="M3780 4751 c-5 -11 -10 -40 -10 -65 0 -35 5 -48 22 -60 28 -20 43
-20 62 0 19 19 22 114 4 132 -19 19 -66 15 -78 -7z"/>
<path d="M1532 4678 c-30 -30 0 -75 40 -62 28 8 38 22 38 52 0 18 -5 22 -33
22 -18 0 -38 -5 -45 -12z"/>
<path d="M2030 4751 c-18 -34 -12 -41 40 -41 52 0 58 7 40 41 -7 12 -21 19
-40 19 -19 0 -33 -7 -40 -19z"/>
<path d="M2440 4760 c-35 -35 -26 -50 30 -50 38 0 50 4 50 15 0 17 -35 55 -50
55 -5 0 -19 -9 -30 -20z"/>
<path d="M2842 4758 c-7 -7 -12 -20 -12 -30 0 -15 8 -18 44 -18 51 0 57 6 45
38 -9 25 -56 31 -77 10z"/>
<path d="M3243 4682 c-11 -7 -17 -26 -14 -48 2 -19 44 -24 68 -8 14 8 23 25
23 39 0 22 -5 25 -32 25 -18 0 -38 -4 -45 -8z"/>
<path d="M3992 4678 c-30 -30 4 -76 45 -61 23 9 33 23 33 51 0 18 -5 22 -33
22 -18 0 -38 -5 -45 -12z"/>
<path d="M4316 4674 c-11 -28 -7 -43 15 -54 28 -16 69 11 69 45 0 22 -4 25
-39 25 -26 0 -41 -5 -45 -16z"/>
<path d="M4706 4754 c-31 -30 -20 -44 34 -44 41 0 50 3 50 18 0 40 -54 57 -84
26z"/>
<path d="M5506 4754 c-31 -30 -20 -44 34 -44 52 0 58 7 40 41 -13 23 -53 25
-74 3z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
